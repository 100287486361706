import { ReactElement, ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PatternFormatProps } from 'react-number-format';

import { format, parse } from 'date-fns';

import { InputLabel } from '@shared/components/InputLabel';
import { InputMask as InputComponent } from '@shared/components/InputMask';

import { InputLoader } from '../InputLoader';
import { useEquitesFormContext } from './EquitesFormProvider/useEquitesForm';

interface IInputProps extends PatternFormatProps {
  adornment?: ReactNode;
  name: string;
  label?: string;
  classNameRoot?: string;
  classNameInputRoot?: string;
  dateFormat?: string;
}

export const InputMaskForm = (props: IInputProps): ReactElement => {
  const {
    adornment,
    name,
    label,
    classNameRoot,
    classNameInputRoot,
    required,
    dateFormat = 'ddMMyyyy',
    ...rest
  } = props;

  const { t } = useTranslation('validations');

  const { control, isLoading } = useEquitesFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={undefined}
      render={({ field: { value = '', onChange }, fieldState: { error } }) => {
        const onSelectDate = (dateSelected: Date): void => {
          onChange(format(dateSelected, dateFormat));
        };

        const handleChange = (date: string): void => {
          onChange(date);
        };

        return (
          <InputComponent.Root className={classNameRoot}>
            {label && <InputLabel required={required}>{label}</InputLabel>}

            <InputComponent.InputRoot className={classNameInputRoot} hasError={Boolean(error?.message)}>
              {isLoading ? (
                <InputLoader />
              ) : (
                <>
                  <InputComponent.Input
                    id={name}
                    type="tel"
                    value={value ?? ''}
                    onValueChange={event => handleChange(event.value)}
                    onSelectDate={onSelectDate}
                    defaultDate={value ? parse(value, dateFormat, new Date()) : new Date()}
                    {...rest}
                  />

                  {adornment && <InputComponent.Icon>{adornment}</InputComponent.Icon>}
                </>
              )}
            </InputComponent.InputRoot>

            {error?.message && (
              <InputComponent.HelpText className="text-red-500">{t(error.message)}</InputComponent.HelpText>
            )}
          </InputComponent.Root>
        );
      }}
    />
  );
};
