import { ReactElement, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { WhatsappLogo } from '@phosphor-icons/react';

import { Button } from '@shared/components/Button';
import { InputForm, InputPasswordForm, SelectForm } from '@shared/components/Form/HookForm';
import { ISelectOption } from '@shared/components/Select/types';
import { Text } from '@shared/components/Text';
import { toast } from '@shared/components/Toast';
import { ContactConfig } from '@shared/configs/Contact';
import { Storage } from '@shared/constants/Storage';

import { useUsers } from '@modules/users/contexts/Users/hooks/useUsers';
import { useSignUpMutation } from '@modules/users/hooks/useSignUpMutation';
import { useValidateEmailAlreadyExistsMutation } from '@modules/users/hooks/useValidateEmailAlreadyExistsMutation';
import { ISignUpSchema, SignUpSchema } from '@modules/users/validations/SignUpValidation';

interface ILabelAndPlaceholder {
  label: string;
  placeholder: string;
}

export const SignUpForm = (): ReactElement => {
  const { t } = useTranslation('users');

  const PROFILES_OPTIONS: ISelectOption[] = [
    { label: t('athlete'), value: 'ATHLETE' },
    { label: t('entity'), value: 'COMPANY' },
    { label: t('association'), value: 'ASSOCIATION' },
  ];

  const form = useForm<ISignUpSchema>({
    defaultValues: { profile: 'ATHLETE' },
    resolver: zodResolver(SignUpSchema),
  });

  const { mutate: signUp, isPending: isLoadingSignUp } = useSignUpMutation();
  const { mutate: verifyEmail, isPending: isLoadingVerify } = useValidateEmailAlreadyExistsMutation();

  const isLoading = isLoadingSignUp || isLoadingVerify;

  const { toggleRegistered } = useUsers();

  const [profilesOptions] = useState(PROFILES_OPTIONS);
  const [profileSelected, setProfileSelected] = useState('ATHLETE');

  const handleSubmitSignUp = async (data: ISignUpSchema): Promise<void> => {
    const email = data.email.toLowerCase().trim();

    verifyEmail(
      { email },
      {
        onError: error => {
          toast(error.response?.data.message, { type: 'error' });
        },
        onSuccess: ({ alreadyExists }) => {
          if (alreadyExists) {
            form.setError('email', { message: 'email_already_exists' });
          } else {
            signUp(
              { ...data, email, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
              {
                onError: error => {
                  toast(error.response?.data.message, { type: 'error' });
                },
                onSuccess: () => {
                  sessionStorage.setItem(Storage.EMAIL_REGISTER, data.email);
                  toggleRegistered();
                  toast(t('messages_register_success'), { type: 'success' });
                },
              },
            );
          }
        },
      },
    );
  };

  const getLabelAndPlaceholderName = (): ILabelAndPlaceholder => {
    switch (profileSelected) {
      case 'ATHLETE':
        return { label: t('name_athlete_label'), placeholder: t('name_athlete_placeholder') };
      case 'COMPANY':
        return { label: t('name_company_label'), placeholder: t('name_company_placeholder') };
      default:
        return { label: '', placeholder: '' };
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmitSignUp)} className="flex w-full flex-col">
        <div className="flex flex-col gap-4">
          <SelectForm
            name="profile"
            label={`${t('this_profile_is_to')}...`}
            placeholder={t('select_profile')}
            options={profilesOptions}
            onSelectOption={({ value }) => setProfileSelected(value)}
          />

          {profileSelected !== 'ASSOCIATION' && (
            <>
              <InputForm
                name="name"
                label={getLabelAndPlaceholderName().label}
                placeholder={getLabelAndPlaceholderName().placeholder}
              />

              <InputForm name="email" type="email" label={t('your_best_email')} placeholder={t('your_access_email')} />

              <InputPasswordForm name="password" label={t('your_password')} placeholder={t('your_password')} />
            </>
          )}
        </div>

        {profileSelected === 'ASSOCIATION' && (
          <>
            <Text className="mb-2 mt-6 text-center text-lg text-black-400">{t('association_message')}</Text>
            <Button className="flex items-center justify-center gap-2" asChild>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://wa.me/${ContactConfig.equitesWhatsAppNumber}?text=${t('association_message_chat')}`}
              >
                <Text className="text-md text-white">{t('go_to_whatsapp')}</Text>
                <WhatsappLogo size={24} className="text-white" />
              </a>
            </Button>
          </>
        )}

        {profileSelected !== 'ASSOCIATION' && (
          <Button type="submit" className="mt-4" isLoading={isLoading} textOnLoading={t('registering')}>
            {`${t('already_ok')}!`}
          </Button>
        )}

        <Button variant="tertiary" asChild className="mt-2 text-center">
          <Link to="/">
            <Text className="text-md font-normal text-green-500 transition-colors hover:text-green-400">
              {t('i_already_have_account')}
            </Text>
          </Link>
        </Button>
      </form>
    </FormProvider>
  );
};
