import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import queryString from 'query-string';

import LogoAnimated from '@shared/assets/gifs/logo-animated-once-time.gif';
import { BackgroundSignIn } from '@shared/components/BackgroundSignIn/BackgroundSignIn';
import { Button } from '@shared/components/Button';
import { CardContainer } from '@shared/components/Cards';
import { InputForm, InputPasswordForm } from '@shared/components/Form/HookForm';
import { Page } from '@shared/components/Page';
import { PrivacyAndTerms } from '@shared/components/PrivacyAndTerms';
import { Text } from '@shared/components/Text';
import { toast } from '@shared/components/Toast';
import { UrlConfig } from '@shared/configs/URL';

import { ISignInSchema, SignInSchema } from '@modules/auth/validations/SignInValidation';

import { useSignInMutation } from '../hooks/useSignInMutation';

export const SignIn = (): ReactElement => {
  const { t } = useTranslation('auth');

  const form = useForm<ISignInSchema>({ resolver: zodResolver(SignInSchema) });

  const { mutate: signIn, isPending } = useSignInMutation();

  const handleSubmitSignIn = (data: ISignInSchema): void => {
    signIn(data, {
      onError: error => {
        toast(error.response?.data.message, { type: 'error' });
      },
      onSuccess: async ({ tokens, user }) => {
        const application: { [key: string]: string } = {
          ASSOCIATION: UrlConfig.clientAssociationsUrl,
          ATHLETE: UrlConfig.clientAthletesUrl,
          COMPANY: UrlConfig.clientCompaniesUrl,
        };

        const profileDefault = user.profiles.find(p => p.isDefault)?.name;

        if (profileDefault) {
          window.location.href = `${application[profileDefault]}/auth?${queryString.stringify(tokens)}`;
        }
      },
    });
  };

  return (
    <Page className="flex h-full w-full items-center justify-center">
      <BackgroundSignIn />

      <CardContainer>
        {/* Logo */}
        <img src={LogoAnimated} alt="Equites" className="mb-2 h-12 w-auto lg:mb-4 lg:h-20" />

        {/* Title */}
        <Text className="mb-4 text-lg font-bold text-black-400 lg:mb-8 lg:text-xl">{t('access')}</Text>

        {/* Form data */}
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmitSignIn)} className="flex w-full flex-col">
            <div className="flex flex-col gap-4">
              <InputForm
                name="username"
                label={t('your_email_or_Equites_id')}
                placeholder={t('your_email_or_Equites_id')}
              />

              <InputPasswordForm name="password" label={t('your_password')} placeholder={t('your_password')} />
            </div>

            <Link to="/forgot-password">
              <Text className="text-xs text-green-500 transition-colors hover:text-green-400">
                {t('forgot_password')}
              </Text>
            </Link>

            <Button type="submit" className="mt-4" isLoading={isPending} textOnLoading={t('accessing')}>
              {t('access')}
            </Button>

            <Button variant="tertiary" className="mt-2 text-center" asChild>
              <Link to="/sign-up">
                <Text className="text-md font-normal text-green-500 transition-colors hover:text-green-400">
                  {`${t('register_free')}!`}
                </Text>
              </Link>
            </Button>
          </form>
        </FormProvider>

        <PrivacyAndTerms />
      </CardContainer>
    </Page>
  );
};
