import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';
import { Text } from '../Text';

export const PrivacyAndTerms = (): ReactElement => {
  const { t } = useTranslation('globals');

  return (
    <div className="mt-2 flex items-center gap-4">
      <Button variant="tertiary" asChild>
        <a target="_blank" rel="noopener noreferrer" href="https://equites.com.br/privacy-policy">
          <Text className="text-xs font-normal text-green-500 transition-colors hover:text-green-400">
            {t('privacy')}
          </Text>
        </a>
      </Button>
      <Button variant="tertiary" asChild>
        <a target="_blank" rel="noopener noreferrer" href="https://equites.com.br/terms">
          <Text className="text-xs font-normal text-green-500 transition-colors hover:text-green-400">
            {t('terms')}
          </Text>
        </a>
      </Button>
    </div>
  );
};
